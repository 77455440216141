table {
    width: 95%;
    margin: 15px auto;
    border-collapse: collapse;
    font-size: 12px;
    thead {
        tr {
            th {
                min-width: 76px;
                text-align: left;
                border-bottom: 2px solid black;
                background-color: white;
                &:first-child {
                    min-width: unset;
                }
            }
        }
        button {
            background-color: white;
            border: 0;
            border-radius: none;
            font-family: inherit;
            font-weight: 700;
            font-size: inherit;
            padding: 0.5em;
            margin-bottom: 1px;
            display: flex;
            &.sort {
                &::after {
                    content: '🡙';
                    display: inline-block;
                    margin-left: 1em;
                }
            }
        }
        & button.ascending::after {
            content: '🡣';
            display: inline-block;
            margin-left: 1em;
        }
        & button.descending::after {
            content: '🡡';
            display: inline-block;
            margin-left: 1em;
        }
    }
    tbody {
        tr {
            td {
                padding: 0.5em;
                border-bottom: 1px solid #ccc;
                text-indent: 2px;
                &:nth-last-child(1) {
                    white-space: nowrap
                }
                a {
                    text-decoration: none;
                }
            }
            &:nth-of-type(2n) {
                background: hsl(0 0% 0% / 0.03);
            }
            &:hover {
                background-color: #eee;
            }
        }
    }
}

.fitContent {
    width:1%;
    white-space: nowrap;
}