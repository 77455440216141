body {
    background: hsla(215, 56%, 22%, 1);
}

.root {
    position: relative;
    // background: hsla(215, 56%, 22%, 1);
}

.login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    border-radius: 6px;
    padding: 25px 25px;
    &__logo {
        display: block;
        margin: 0 auto;
        width: 80%;
    }
    &__error {
        text-align: center;
        color: red;
        min-height: 24px;
    }
}