@import 'fonts';
@import 'variables';
@import 'components/all';
@import 'media';
@import "custom_bootstrap";
.error {
    color: $color-alert;
    color: #DE694F;
    text-align: center;
    margin: 45px 0;
}

.hide {
    display: none;
}