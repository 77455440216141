.alert_section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    right: 10px;
    bottom: 10px;
    position: fixed;
    z-index: 100;
    &_stick_br{
        margin-bottom: 0 !important;
    }
}
