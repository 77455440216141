.tech-suspension{
    margin: 25px auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 900px;
    .tech_suspension{
        background-color: $color_tech_suspension;
        border: 1px solid $color_tech_suspension;
        color: white
    }
    .commerce_activated {
        background-color: $color_activate_light;
        border: 1px solid $color_activate_light;
        color: white
    }
    &_col_middle {
        border-radius: 0 0 0 0 !important;
    }
    &_col_left {
        border-radius: 0.375rem 0 0 0.375rem !important;
    }
    &_col_right{
        border-radius: 0 0.375rem 0.375rem 0 !important;
    }
    span:has(> input) {
        border-radius: 0.375rem 0 0 0.375rem !important;
    }
    &_service_name_col {
        border-radius: 0 0 0 0 !important;
    }
    &_service_status_col {
        border-radius: 0 0 0 0 !important;
    }
    &_service_btn_col {
        border-radius: 0 0.375rem 0.375rem 0 !important;
        border: 0
    }
}
.services {
    margin: 25px auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &__search {
        padding: 25px 0 0 0;
        width: 820px;
        margin: 0 auto;
    }
    &__wrapper {
        max-width: 920px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 0px 50px 100px 50px;
        &_item {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 400px;
            min-height: 209px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            padding: 0 10px 10px 10px;
            &_status {
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                color: white;
                font-size: 14px;
                text-align: center;
                margin: 0 -10px 0 -10px;
                &.offline{
                    background-color: $color_deactivate;
                }
                &.online {
                    background-color: $color_activate;
                }
            }
            &_block {
                display: flex;
                justify-content: space-between;
            }
            &_srv {
                text-transform: uppercase;
                opacity: 0.6;
                font-size: 14px;
                font-weight: $font-regular;
            }
            &_account {
                opacity: 0.6;
                font-size: 14px;
                font-weight: $font-regular;
            }
            &_name__c {
                display: flex;
                flex-direction: row;
                font-size: 18px;
                font-weight: $font-regular;
            }
            &_status__c {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 40px;
                opacity: 0.8;
                &.deactivated {
                    color: $color-deactivate
                }
                &.activated {
                    color: $color-activate
                }
                &.approved {
                    color: $color_activate_light
                }
            }
        }
    }
    .spinner-border {
        display: block;
        margin: 25px auto;
    }
}

.remote{
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:last-child hr{
        display: none;
    }
    &_isOnline{
        font-size: 10px;
        margin: 0 auto;
        &.offline{
            color: $color_deactivate;
        }
        &.online {
            color: $color_activate;
        }
    }
    &_cir_mir{
        display: flex;
        justify-content: space-between;
        &_wrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 5px;
        }
        &_title {
            font-size: 12px;
        }
        &_value {
            font-size: 12px;
            text-align: center;
        }
    }
    &_name {
        font-size: 13px;
        text-align: center;
        font-weight: 700;
        color: $color-primary;
    }
    &_satellite {
        font-size: 10px;
        text-align: center;
        color: $color_activate;
    }
    hr {
        margin-top: 3px;
    }
    &_open {
        display: flex;
        margin-top: auto;
        flex-direction: row;
        background-color: #fff;
        color: #000000;
        border: 0;
        font-size: 14px;
        padding: 0 0 0 0;
        justify-content: space-evenly;
        &_remote {
            cursor: pointer;
            color: $color-primary;
            &[disabled] { 
                pointer-events: none;
                color: $color-btn_cancel
            }
        }
        &_statistics {
            cursor: pointer;
            color: $color-secondary;
            text-decoration: none;
            &_disable {
                cursor: none;
                pointer-events: none;
                color: $color_deactivate;
                text-decoration: none;
            }
        }
        &_options_file{
            cursor: pointer;
            color: $color-primary;
            font-size: 10px;
            font-weight: 600;
            &[disabled] { 
                pointer-events: none;
                color: $color-btn_cancel
            }
        }
    }
}