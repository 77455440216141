.modal_task {
    display: flex;
    flex-direction: column;
    gap: 15px;
    right: 10px;
    bottom: 10px;
    position: fixed;
    z-index: 100;
    &_main {
        height: 70px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        font-size: 16px;
        text-indent: 10px;
        min-width: 350px;
        border-radius: 10px;
        color: #fff;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        background: rgba(116, 116, 145, 0.8);
        &_service-name {
            text-align: center;
            font-size: 10px;
        }
        &_result {
            margin-bottom: 5px;
        }
        &_head {
            display: flex;
            justify-content: space-between;
            margin-right: 10px;
        }
        &_success {
            background: rgba(0, 93, 53, 0.8);
        }
        &_failure {
            background: rgba(127, 54, 41, 0.8);
        }
        &_process {
            background: rgba(63, 146, 207, 0.8);
        }
        &_retry {
            background: rgba(44, 121, 145, 0.8);
        }
        &_revoked {
            background: rgba(119, 151, 160, 0.8);
        }
        &_close {
            cursor: pointer;
            border: 1px solid #AEAEAE;
            border-radius: 30px;
            background: $color-primary;
            position: absolute;
            z-index: 1;
            width: 24px;
            height: 24px;
            top: -12px;
            right: -8px;
            &:before {
                font-size: 36px;
                color: #fff;
                line-height: 0px;
                position: absolute;
                content: "×";
                top: 9px;
                right: 1px;
            }
        }
        .hide {
            display: none;
        }
    }
}