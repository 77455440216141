.statistics {
    &_status{
        font-size: 3vw;
        &.deactivated {
            color: $color_deactivate;
        }
        &.activated {
            color: $color_activate;
        }
    }
    &_satellite {
        font-size: 3vw;
        color: $primary;
    }
}