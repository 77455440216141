$btn-border-radius: 1.2rem;
$color-primary: #193459;
$color-secondary: #3f92cf;
$color-logout: #fff;
$color-alert: #DE694F;
$color_activate: #005D35;
$color_activate_light: #7ca795;
$color_tech_suspension: #a89794;
$color_deactivate: #7F3629;
$color-bcg: #fff;
$color-btn_cancel: #6c757d;
$font-regular: 400;
$font-bold: 700;
$container-width: 1140px;
$duration: 0.15s;
$primary:#193459;