@media (max-width: 1100px) {
    table {
        font-size: 10px;
    }
}

@media (max-width: 993px) {
    .services {
        &__wrapper {
            justify-content: center;
        }
        &__search {
            display: block;
            margin: auto;
            max-width: 400px;
            min-width: 400px;
        }
    }
}

@media (max-width: 780px) {
    th {
        display: none;
    }
    tbody {
        td {
            font-size: 16px;
            display: grid;
            grid-template-columns: 10ch auto;
            padding: 0;
            border-bottom: none;
            &[data-cell] {
                &::before {
                    content: attr(data-cell)": ";
                    font-weight: 700;
                    text-transform: capitalize;
                }
            }
            &:first-child {
                padding-top: 1rem;
            }
            &:last-child {
                padding-bottom: 1rem;
                border-bottom: 1px solid;
            }
            &:last-child:has(button) {
                padding-bottom: 8px;
            }
            button{
                width: 100% !important;
            }
        }
    }
    .home{
        &_service_card{
            display: flex;
            flex-direction: column;
            justify-content: center;
            &_list{
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 500px) {
    .login {
        width: 90%;
        padding: 15px 15px;
    }
    .services {
        &__wrapper {
            padding-left: 0;
            padding-right: 0;
            &_item {
                width: 95%;
            }
        }
        &__search {
            max-width: unset;
            min-width: unset;
            width: 95%;
            &__input {
                width: 100%;
            }
        }
    }
    .modal_task {
        width: 100%;
        align-items: center;
        right: 0;
        &_main {
            width: 95%;
        }
    }
}

@media (max-width: 768px) {
    .tech-suspension{
        &_col_middle {
            border-radius: 0 0.375rem 0 0 !important;
        }
        &_col_left {
            border-radius: 0.375rem 0 0 0 !important;
        }
        &_col_right{
            border-radius: 0 0 0.375rem 0.375rem !important;
        }
        span:has(> input) {
            border-radius: 0.375rem 0 0 0 !important;
        }
        &_service_name_col {
            border-radius: 0 0.375rem 0 0 !important;
        }
        &_service_status_col {
            border-radius: 0 0 0 0.375rem !important;
        }
        &_service_btn_col {
            border-radius: 0 0 0.375rem 0 !important;
        }
    }
}

@media (max-width: 576px) {
    .tech-suspension{
        &_col_middle {
            border-radius: 0 0 0 0 !important;
        }
        &_col_left {
            border-radius: 0.375rem 0.375rem 0 0 !important;
        }
    }
}