.btn_login {
    width: 100%;
}

.btn_newUser {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 25px;
    right: 25px;
}
.btn_unblock {
    display: block !important;
    margin: auto !important;
}

.btn_block{
    display: block !important;
    margin: auto !important;
}

.btn_add_modcode {
    text-align: center;
    width: 35px !important;
    height: 35px !important;
    border-radius: 100% !important; 
    display: inline-flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-left: auto;
}