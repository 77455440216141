.home{
    &_service_card {
        display: flex;
        justify-content: center;
        &_list {
            margin-top: auto;
        }
        &_img {
            margin-right: 0;
        }
    }
}