.task {
    margin: 25px auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &__wrapper {
        max-width: 1060px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 0px 50px 100px 50px;
        &_item {
            display: flex;
            flex-direction: column;
            width: 305px;
            min-height: 125px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            padding: 10px 10px;
            color: #fff;
            background: rgba(119, 151, 160, 0.8);
            &:last-child {
                margin-top: auto;
            }
            &_success {
                background: rgba(0, 93, 53, 0.8);
            }
            &_failure {
                background: rgba(127, 54, 41, 0.8);
            }
            &_process {
                background: rgba(63, 146, 207, 0.8);
            }
            &_retry {
                background: rgba(44, 121, 145, 0.8);
            }
            &_revoked {
                background: rgba(183, 192, 194, 0.8);
            }
        }
    }
}