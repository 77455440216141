$font-family-sans-serif: 'Ubuntu',
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif !default;
$popover-max-width: 330px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1820px
);


@import "~bootstrap/scss/bootstrap";